.App {
  text-align: center;
  background-color: #fff;
  color: #000;
}

body, html {
  min-height: 100vh;
  background-color: black;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #fff; /* Or any color you'd like for the header */
  /* Remove or reduce the min-height value */
  min-height: 60px; /* This is just an example; you can set it to the height you want */
  padding: 20px 0; /* Add some padding if you want some space inside the header */
  display: flex;
  flex-direction: row; /* Change it to row to align items horizontally if that's your design */
  align-items: center; /* This will vertically center the content in the header */
  justify-content: center; /* This will horizontally center the content in the header */
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Logo {
  width: 50px; /* Adjust the width to your desired size */
  height: auto; /* Allows the height to adjust proportionally based on the width */
  max-width: 100%; /* Ensures the image doesn't exceed its container */
  margin-right: 20px;
}
